import {
  Button, Tooltip, Input, Modal, Checkbox, Tag,
} from 'antd'
import { parse, stringify } from 'query-string'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import StarRatings from 'react-star-ratings';
import Loader from '../../../common/components/Loader'
import InputWithCopy from '../CopyToClipboard';
import {
  deletePiFromNetworkAction,
  getNetworkDetailsAction,
  getPiAutoSuggestAction,
  getPiAutoSuggestResetAction,
  putPiToNetworkAction,
  getPiAutoSuggestMoreAction,
  getNetworkDetailsMoreAction,
} from './logic'
import LazyLoadDiv from '../../../common/components/LazyLoadDiv';

const NetworkCenterList = ({ appliedFilters }) => {
  const networkData = useSelector((store) => store.getNetworkDetails)
  const deletePiFromNetwork = useSelector((store) => store.deletePiFromNetwork)
  const piAutoSuggest = useSelector((store) => store.getPiAutoSuggest)
  const putPiToNetwork = useSelector((store) => store.putPiToNetwork)
  const [networkMetaDetails, setNetworkMetaDetails] = useState({})
  const [localLoading, setLocalLoading] = useState(true)
  const [piList, setPiList] = useState()
  const [piValueToSearch, setPiValueToSearch] = useState('')
  const [addPiToNetworkModal, setAddNewPiToNetworkModal] = useState(false)
  const [fromForPi, setFromForPi] = useState(1);
  const [fromForPiInNetworkList, setFromForPiInNetworkList] = useState(1)
  const [deleteModal, setDeleteModal] = useState(false)
  const [piListToSend, setPiListToSend] = useState([])
  const [piNameToSearch, setPiNameToSearch] = useState('')
  const [piIdToDelete, setPiIdToDelete] = useState('')

  const dispatch = useDispatch()
  const modifyNetworkRespose = useSelector((store) => store.modifyNetwork);

  const { Search } = Input

  const type = 'centers';

  useEffect(() => {
    const network_id = parse(location.search).id
    const from = 1
    const size = 10
    const filters = JSON.stringify({ ...appliedFilters, parent_flag: false })

    dispatch(getNetworkDetailsAction({
      type, network_id, from, size, filters,
    }))
    setFromForPiInNetworkList(fromForPiInNetworkList + 1)
  }, [])

  useEffect(() => {
    const network_id = parse(location.search).id
    const from = 1
    const size = 10
    const filters = JSON.stringify({ ...appliedFilters, parent_flag: false })

    dispatch(getNetworkDetailsAction({
      type, network_id, from, size, filters,
    }))
  }, [appliedFilters])

  useEffect(() => {
    if (modifyNetworkRespose.flag) {
      const network_id = parse(location.search).id
      const from = 1
      const size = 10
      const filters = JSON.stringify({ ...appliedFilters, parent_flag: false })
      dispatch(getNetworkDetailsAction({
        type, network_id, from, size, filters,
      }))
    }
  }, [JSON.stringify(modifyNetworkRespose)]);

  useEffect(() => {
    if (putPiToNetwork.flag) {
      const network_id = parse(location.search).id
      const from = 1
      const size = 10
      const filters = JSON.stringify({ ...appliedFilters, parent_flag: false })
      dispatch(getNetworkDetailsAction({
        type, network_id, from, size, filters,
      }))
    }
  }, [JSON.stringify(putPiToNetwork)])

  useEffect(() => {
    if (networkData.flag) {
      setNetworkMetaDetails(networkData.data.data.network_details)
      setPiList(networkData.data.data.pi_summary)
      setLocalLoading(false)
    }
  }, [JSON.stringify(networkData)])

  useEffect(() => {
    if (deletePiFromNetwork.flag) {
      const network_id = parse(location.search).id
      const from = 1
      const size = 10
      const filters = JSON.stringify({ ...appliedFilters, parent_flag: false })
      dispatch(getNetworkDetailsAction({
        type, network_id, from, size, filters,
      }))
    }
  }, [JSON.stringify(deletePiFromNetwork)])

  const onInvestigatorChangeInput = (e) => {
    if (e.trim() && e.length > 2) {
      setPiNameToSearch(e)
      dispatch(
        getPiAutoSuggestAction({
          query: e.trim(),
          from: 1,
          dataset: 'centers',
          size: 10,
          filters: JSON.stringify({ parent_flag: false }),
        }),
      );
      setFromForPi(fromForPi + 1)
    }
  };

  const loadMoreRowsPiList = () => {
    dispatch(
      getPiAutoSuggestMoreAction({
        query: piNameToSearch,
        from: fromForPi,
        dataset: 'centers',
        size: 10,
        filters: JSON.stringify({ parent_flag: false }),
      }),
    );
    setFromForPi(fromForPi + 1)
  }

  const loadMoreRowsPiListInNetwork = () => {
    const network_id = parse(location.search).id
    const from = fromForPiInNetworkList
    const size = 10
    const filters = JSON.stringify({ ...appliedFilters, parent_flag: false })
    dispatch(getNetworkDetailsMoreAction(
      {
        network_id, from, size, filters, type,
      },
    ))
  }

  const handleInvestigatorCheck = (id) => {
    if (piListToSend.includes(id)) {
      const listToSend = piListToSend.filter((ele) => ele !== id);
      setPiListToSend(listToSend);
    } else {
      setPiListToSend((current) => [...current, id]);
    }
  }

  const renderAutoSuggestOptions = () => {
    if (piAutoSuggest.data.data.length === 0) {
      return (
        <div className="no-data-found-msg">No Centers found</div>
      )
    }
    return piAutoSuggest.data.data.map((itm) => {
      return (
        <>
          <div
            className={`card ${itm.type}`}
          >
            <div className="card-header">
              <div className="card-title">{itm.name}</div>
            </div>
            <div className="content">
              {/* <div className="leftside">
                <div className="person-details">
                  <div className="contact_name">
                    <div className="title-child">Address:</div>
                    {itm.address
                      ? (
                        <div className="content-child">{`${itm.address.city ? itm.address.city : ''}${itm.address.city ? ', ' : ''}${itm.address.state ? itm.address.state : ''}${itm.address.state ? ', ' : ''}${itm.address.country ? itm.address.country : ''}${itm.address.country ? ', ' : ''}${itm.address.zip ? itm.address.zip : ''}`}</div>
                      )
                      : (
                        <div className="content-child no-data">No data available</div>
                      )}
                  </div>
                  <div className="emailofcontact">
                    <div className="title-child">Email</div>
                    {itm.email && itm.email.length
                      ? (
                        <div className="content-child">{itm.email.join(', ')}</div>
                      )
                      : (
                        <div className="content-child no-data">No data available</div>
                      )}
                  </div>
                  <div className="mobileOfcontact">
                    <div className="title-child">Phone</div>
                    {itm.phone_number && itm.phone_number.length
                      ? (
                        <div className="content-child">{itm.phone_number.join(', ')}</div>
                      )
                      : (
                        <div className="content-child no-data">No data available</div>
                      )}
                  </div>
                </div>
              </div>
              <div className="right-side right-side-with-sep">
                <div className="tags-data">
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.tier}</div>
                      <div className="sizeOftag">{itm.tier}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.alliance}</div>
                      <div className="sizeOftag">{itm.alliance ? 'Yes' : 'No'}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.compliance}</div>
                      <div className="sizeOftag">{itm.compliance ? 'Yes' : 'No'}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.total_number_of_popis}</div>
                      <div className="sizeOftag">{itm.total_number_of_popis}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.interventional_studies}</div>
                      <div className="sizeOftag">{itm.interventional_studies}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.observational_studies}</div>
                      <div className="sizeOftag">{itm.observational_studies}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.parexel_studies}</div>
                      <div className="sizeOftag">{itm.parexel_studies}</div>
                    </div>
                  </Tag>
                  {/* <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.no_of_indications}</div>
                      <span className="sizeOftag">
                        {itm.indications && itm.indications.length
                          ? itm.indications.length
                          : 0}
                      </span>
                    </div>
                  </Tag> */}
              {/* <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.indications}</div>
                      <Tooltip
                        placement="bottom"
                        title={
                                itm.indications && itm.indications.length
                                  ? itm.indications.join('; ')
                                  : ''
                              }
                      >
                        <span className="sizeOftag">
                          {itm.indications && itm.indications.length
                            ? itm.indications.join('; ')
                            : ''}
                        </span>
                      </Tooltip>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">Sources: &nbsp;</div>
                      <Tooltip
                        placement="bottom"
                        title={
                                itm.sources && itm.sources.length
                                  ? itm.sources.join('; ')
                                  : ''
                              }
                      >
                        <span className="sizeOftag">
                          {itm.sources && itm.sources.length
                            ? itm.sources.join('; ')
                            : '-'}
                        </span>
                      </Tooltip>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">Center Type: &nbsp;</div>
                      <span className="sizeOftag">
                        {itm.center_type ? itm.center_type : '-'}
                      </span>
                    </div>
                  </Tag>
                </div>
                <div className="reliability-quality-rating">
                  <div className="reliability rating-sec">
                    <div className="rating-sec-title">Reliability</div>
                    {itm.reliability && itm.reliability !== ''
                      ? (
                        <StarRatings
                          rating={itm.reliability}
                          starRatedColor="#8A0050"
                          numberOfStars={3}
                          starDimension="16px"
                          starSpacing="1px"
                          starEmptyColor="#D099B9"
                        />
                      )
                      : (
                        <div className="content-child no-data">&nbsp;No data available</div>
                      )}
                  </div>
                  <div className="quality rating-sec">
                    <div className="rating-sec-title">Quality</div>
                    {itm.quality && itm.quality !== ''
                      ? (
                        <StarRatings
                          rating={itm.quality}
                          starRatedColor="#8A0050"
                          numberOfStars={3}
                          starDimension="16px"
                          starSpacing="1px"
                          starEmptyColor="#D099B9"
                        />
                      )
                      : (
                        <div className="content-child no-data">&nbsp;No data available</div>
                      )}
                  </div>
                </div>
              </div> */}

              <div className="leftside">
                <div className="person-details">
                  <div className="contact_name">
                    <div className="title-child">Address:</div>
                    {itm.address
                      ? (
                        <div className="content-child">{itm?.address?.address_line1 && `${itm?.address?.address_line1}, `}
                          {itm?.address?.address_line2 && `${itm?.address?.address_line2}, `}
                          {itm?.address?.city && `${itm?.address?.city}, `}
                          {itm?.address?.state && `${itm?.address?.state}, `}
                          {itm?.address?.country && `${itm?.address?.country}, `}
                          {itm?.address?.zip}
                        </div>
                      )
                      : (
                        <div className="content-child no-data">No data available</div>
                      )}
                  </div>
                  <div className="emailofcontact">
                    <div className="title-child">Email:</div>
                    {itm.email && itm.email.length
                      ? (
                        <>
                          <div className="content-child">
                            {itm.email.join(', ')}
                            <InputWithCopy text={itm} type="email" />
                          </div>
                        </>
                      )
                      : (
                        <div className="content-child no-data">No data available</div>
                      )}
                  </div>
                  <div className="mobileOfcontact">
                    <div className="title-child">Phone:</div>
                    {itm.phone_number && itm.phone_number.length
                      ? (
                        <div className="content-child">{itm.phone_number.join(', ')}</div>
                      )
                      : (
                        <div className="content-child no-data">No data available</div>
                      )}
                  </div>
                </div>
              </div>
              <div className="right-side">
                <div style={{ display: 'flex' }}>
                  <div style={{ color: '#848484' }}> Parent Institution Name: </div>
                  {itm?.parent_institution && Object.keys(itm?.parent_institution).length ? (
                    itm?.parent_institution?.name

                  ) : (
                    <span className="value" style={{ fontSize: '14px' }}>&nbsp;No data available</span>
                  )}
                </div>
                <div className="tags-data">
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.tier}</div>
                      <div className="sizeOftag" role="presentation">{itm.tier}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.alliance}</div>
                      <div className="sizeOftag">{itm.alliance_type ? itm.alliance_type : '-'}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.compliance}</div>
                      <div className="sizeOftag">{itm.compliance ? 'Yes' : 'No'}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.total_number_of_popis}</div>
                      <div className="sizeOftag">{itm.total_number_of_popis}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.industry_studies}</div>
                      <div className="sizeOftag">{itm.industry_studies}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.interventional_studies}</div>
                      <div className="sizeOftag">{itm.interventional_studies}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.observational_studies}</div>
                      <div className="sizeOftag">{itm.observational_studies}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.parexel_studies}</div>
                      <div className="sizeOftag">{itm.parexel_studies}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.enrollment_subjects}</div>
                      <span className="sizeOftag">
                        {itm.enrollment_subjects}
                      </span>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.department}</div>
                      <span className="sizeOftag">
                        {itm.department ? itm.department : '-'}
                      </span>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.indications}</div>
                      <Tooltip
                        placement="bottom"
                        title={
                            itm.indications && itm.indications.length
                              ? itm.indications.join('; ')
                              : '-'
                          }
                      >
                        <span className="sizeOftag">
                          {itm.indications && itm.indications.length
                            ? itm.indications.join('; ')
                            : '-'}
                        </span>
                      </Tooltip>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">Sources&nbsp;</div>
                      <Tooltip
                        placement="bottom"
                        title={
                            itm.sources && itm.sources.length
                              ? itm.sources.join(', ')
                              : '-'
                          }
                      >
                        <span className="sizeOftag">
                          {itm.sources && itm.sources.length
                            ? itm.sources.join(', ')
                            : '-'}
                        </span>
                      </Tooltip>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">Center type&nbsp;</div>
                      <Tooltip
                        placement="bottom"
                        title={
                            itm.center_type
                              ? itm.center_type
                              : '-'
                          }
                      >
                        <span className="sizeOftag">
                          {itm.center_type
                            ? itm.center_type
                            : '-'}
                        </span>
                      </Tooltip>
                    </div>
                  </Tag>
                  {itm.pxl_center_sims_ids
                    ? (
                      <Tag>
                        <div className="details-abt-tags">
                          <div className="nameOfTag">SIMS IDs&nbsp;</div>
                          <Tooltip
                            placement="bottom"
                            title={
                                itm.pxl_center_sims_ids && itm.pxl_center_sims_ids.length
                                  ? itm.pxl_center_sims_ids.join(', ')
                                  : '-'
                              }
                          >
                            <span className="sizeOftag">
                              {itm.pxl_center_sims_ids && itm.pxl_center_sims_ids.length ? itm.pxl_center_sims_ids.join(', ') : '-'}
                            </span>
                          </Tooltip>
                        </div>
                      </Tag>
                    )
                    : null}
                  {itm.pxl_center_cit_ids
                    ? (
                      <Tag>
                        <div className="details-abt-tags">
                          <div className="nameOfTag">Citeline IDs&nbsp;</div>
                          <Tooltip
                            placement="bottom"
                            title={
                                itm.pxl_center_cit_ids && itm.pxl_center_cit_ids.length
                                  ? itm.pxl_center_cit_ids.join(', ')
                                  : '-'
                              }
                          >
                            <span className="sizeOftag">
                              {itm.pxl_center_cit_ids && itm.pxl_center_cit_ids.length ? itm.pxl_center_cit_ids.join(', ') : '-'}
                            </span>
                          </Tooltip>
                        </div>
                      </Tag>
                    )
                    : null}

                  {itm.landscape_id
                    ? (
                      <Tag>
                        <div className="details-abt-tags">
                          <div className="nameOfTag">LandSCAPE ID&nbsp;</div>
                          <Tooltip
                            placement="bottom"
                            title={
                                itm.landscape_id ? itm.landscape_id
                                  : '-'
                              }
                          >
                            <span className="sizeOftag">
                              {itm.landscape_id ? itm.landscape_id : '-'}
                            </span>
                          </Tooltip>
                        </div>
                      </Tag>
                    )
                    : null}
                </div>
                <div className="reliability-quality-rating">
                  <div className="reliability rating-sec">
                    <div className="rating-sec-title">Reliability</div>
                    {itm.reliability_class && itm.reliability_class !== ''
                      ? (
                        <StarRatings
                          rating={getStars(itm.reliability_class)}
                          starRatedColor="#8A0050"
                          numberOfStars={3}
                          starDimension="16px"
                          starSpacing="1px"
                          starEmptyColor="#D099B9"
                        />
                      )
                      : (
                        <div className="content-child no-data">&nbsp;No data available</div>
                      )}
                  </div>
                </div>
              </div>

              <Checkbox onClick={() => handleInvestigatorCheck(itm.id)} />
            </div>
          </div>
        </>
      )
    })
  }

  const tagTitle = {
    tier: 'Tier',
    compliance: 'Compliance',
    alliance: 'Alliance type',
    indications: 'Indications',
    total_number_of_popis: 'Total PIs',
    industry_studies: 'Industry studies',
    interventional_studies: 'Interventional studies',
    observational_studies: 'Observational studies',
    parexel_studies: 'Parexel studies',
    enrollment_subjects: 'Enrolled subjects',
    department: 'Department',
  };

  const searchPiValue = () => {
    const from = 1
    const size = 10
    const network_id = parse(location.search).id
    const filters = JSON.stringify({ ...appliedFilters, parent_flag: false })
    const query = piValueToSearch
    dispatch(getNetworkDetailsAction({
      type, network_id, query, from, size, filters,
    }))
  }

  const handleAddPiToNetwork = () => {
    dispatch(
      putPiToNetworkAction({
        innoplexus_id: networkMetaDetails.innoplexus_id,
        type,
        pi_id: piListToSend,
      }),
    );
    setPiListToSend([])
    dispatch(getPiAutoSuggestResetAction({}))
    setAddNewPiToNetworkModal(false)
  }
  const handleDeletePi = () => {
    const pi_id = piIdToDelete.id

    dispatch(deletePiFromNetworkAction({
      pi_id,
      innoplexus_id: networkMetaDetails.innoplexus_id,
      type,
    }))
    setPiIdToDelete(false)
    setDeleteModal(false)
  }

  const handleDeleteClick = (id) => {
    setPiIdToDelete(id)
    setDeleteModal(true)
  }
  const getStars = (value) => {
    if (value === 'Class 3') {
      return 1
    }
    if (value === 'Class 2') {
      return 2
    }
    if (value === 'Class 1') {
      return 3
    }
    return 0
  }
  const renderPiList = () => {
    if (piList.length === 0) {
      return <div className="no-data-found-msg">No Centers in this network</div>
    }
    return piList.map((itm) => {
      return (
        <>
          <div
            className={`card ${itm.type}`}
          >
            <div className="card-header">
              <div className="card-title">

                <a
                  className="card-content-title pi-wrap"
                  title={itm.name}
                  target="_blank"
                  href={`/search/deepdives?${stringify({
                    query: itm.name,
                    id: itm.id,
                    type: 'SITE',
                    currentTab: itm.parent_flag ? 'parent_centers' : 'centers',
                  })}`}
                  rel="noreferrer noopener"
                >
                  {itm.name}
                </a>
              </div>
            </div>
            <div className="content">
              <div className="leftside">
                <div className="person-details">
                  <div className="contact_name">
                    <div className="title-child">Address:</div>
                    {itm.address
                      ? (
                        <div className="content-child">{itm?.address?.address_line1 && `${itm?.address?.address_line1}, `}
                          {itm?.address?.address_line2 && `${itm?.address?.address_line2}, `}
                          {itm?.address?.city && `${itm?.address?.city}, `}
                          {itm?.address?.state && `${itm?.address?.state}, `}
                          {itm?.address?.country && `${itm?.address?.country}, `}
                          {itm?.address?.zip}
                        </div>
                      )
                      : (
                        <div className="content-child no-data">No data available</div>
                      )}
                  </div>
                  <div className="emailofcontact">
                    <div className="title-child">Email:</div>
                    {itm.email && itm.email.length
                      ? (
                        <>
                          <div className="content-child">
                            {itm.email.join(', ')}
                            <InputWithCopy text={itm} type="email" />
                          </div>
                        </>
                      )
                      : (
                        <div className="content-child no-data">No data available</div>
                      )}
                  </div>
                  <div className="mobileOfcontact">
                    <div className="title-child">Phone:</div>
                    {itm.phone_number && itm.phone_number.length
                      ? (
                        <div className="content-child">{itm.phone_number.join(', ')}</div>
                      )
                      : (
                        <div className="content-child no-data">No data available</div>
                      )}
                  </div>
                </div>
              </div>
              <div className="right-side">
                <div style={{ display: 'flex' }}>
                  <div style={{ color: '#848484' }}> Parent Institution Name: </div>
                  {itm?.parent_institution && Object.keys(itm?.parent_institution).length ? (
                    <a
                      target="_blank"
                      href={`/search/deepdives?${stringify({
                        ...parse(location.search),
                        id: itm?.parent_institution?.id,
                        currentTab: 'parent_centers',
                      })}`}
                      rel="noopener noreferrer"
                    >&nbsp;{itm?.parent_institution?.name}
                    </a>
                  ) : (
                    <span className="value" style={{ fontSize: '14px' }}>&nbsp;No data available</span>
                  )}
                </div>
                <div className="tags-data">
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.tier}</div>
                      <div className="sizeOftag" role="presentation">{itm.tier}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.alliance}</div>
                      <div className="sizeOftag">{itm.alliance_type ? itm.alliance_type : '-'}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.compliance}</div>
                      <div className="sizeOftag">{itm.compliance ? 'Yes' : 'No'}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.total_number_of_popis}</div>
                      <div className="sizeOftag">{itm.total_number_of_popis}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.industry_studies}</div>
                      <div className="sizeOftag">{itm.industry_studies}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.interventional_studies}</div>
                      <div className="sizeOftag">{itm.interventional_studies}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.observational_studies}</div>
                      <div className="sizeOftag">{itm.observational_studies}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.parexel_studies}</div>
                      <div className="sizeOftag">{itm.parexel_studies}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.enrollment_subjects}</div>
                      <span className="sizeOftag">
                        {itm.enrollment_subjects}
                      </span>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.department}</div>
                      <span className="sizeOftag">
                        {itm.department ? itm.department : '-'}
                      </span>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.indications}</div>
                      <Tooltip
                        placement="bottom"
                        title={
                            itm.indications && itm.indications.length
                              ? itm.indications.join('; ')
                              : '-'
                          }
                      >
                        <span className="sizeOftag">
                          {itm.indications && itm.indications.length
                            ? itm.indications.join('; ')
                            : '-'}
                        </span>
                      </Tooltip>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">Sources&nbsp;</div>
                      <Tooltip
                        placement="bottom"
                        title={
                            itm.sources && itm.sources.length
                              ? itm.sources.join(', ')
                              : '-'
                          }
                      >
                        <span className="sizeOftag">
                          {itm.sources && itm.sources.length
                            ? itm.sources.join(', ')
                            : '-'}
                        </span>
                      </Tooltip>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">Center type&nbsp;</div>
                      <Tooltip
                        placement="bottom"
                        title={
                            itm.center_type
                              ? itm.center_type
                              : '-'
                          }
                      >
                        <span className="sizeOftag">
                          {itm.center_type
                            ? itm.center_type
                            : '-'}
                        </span>
                      </Tooltip>
                    </div>
                  </Tag>
                  {itm.pxl_center_sims_ids
                    ? (
                      <Tag>
                        <div className="details-abt-tags">
                          <div className="nameOfTag">SIMS IDs&nbsp;</div>
                          <Tooltip
                            placement="bottom"
                            title={
                                itm.pxl_center_sims_ids && itm.pxl_center_sims_ids.length
                                  ? itm.pxl_center_sims_ids.join(', ')
                                  : '-'
                              }
                          >
                            <span className="sizeOftag">
                              {itm.pxl_center_sims_ids && itm.pxl_center_sims_ids.length ? itm.pxl_center_sims_ids.join(', ') : '-'}
                            </span>
                          </Tooltip>
                        </div>
                      </Tag>
                    )
                    : null}
                  {itm.pxl_center_cit_ids
                    ? (
                      <Tag>
                        <div className="details-abt-tags">
                          <div className="nameOfTag">Citeline IDs&nbsp;</div>
                          <Tooltip
                            placement="bottom"
                            title={
                                itm.pxl_center_cit_ids && itm.pxl_center_cit_ids.length
                                  ? itm.pxl_center_cit_ids.join(', ')
                                  : '-'
                              }
                          >
                            <span className="sizeOftag">
                              {itm.pxl_center_cit_ids && itm.pxl_center_cit_ids.length ? itm.pxl_center_cit_ids.join(', ') : '-'}
                            </span>
                          </Tooltip>
                        </div>
                      </Tag>
                    )
                    : null}

                  {itm.landscape_id
                    ? (
                      <Tag>
                        <div className="details-abt-tags">
                          <div className="nameOfTag">LandSCAPE ID&nbsp;</div>
                          <Tooltip
                            placement="bottom"
                            title={
                                itm.landscape_id ? itm.landscape_id
                                  : '-'
                              }
                          >
                            <span className="sizeOftag">
                              {itm.landscape_id ? itm.landscape_id : '-'}
                            </span>
                          </Tooltip>
                        </div>
                      </Tag>
                    )
                    : null}
                </div>
                <div className="reliability-quality-rating">
                  <div className="reliability rating-sec">
                    <div className="rating-sec-title">Reliability</div>
                    {itm.reliability_class && itm.reliability_class !== ''
                      ? (
                        <StarRatings
                          rating={getStars(itm.reliability_class)}
                          starRatedColor="#8A0050"
                          numberOfStars={3}
                          starDimension="16px"
                          starSpacing="1px"
                          starEmptyColor="#D099B9"
                        />
                      )
                      : (
                        <div className="content-child no-data">&nbsp;No data available</div>
                      )}
                  </div>
                </div>
              </div>

              <Button className="del-icon-btn second-del" type="primary" onClick={() => handleDeleteClick(itm)} />
            </div>
          </div>
        </>
      )
    })
  }

  const handleAddNewPiToNetworkModalClose = () => {
    dispatch(getPiAutoSuggestResetAction())
    setPiListToSend([])
    setAddNewPiToNetworkModal(false)
  }
  const handleAddPiToNetworkClick = () => {
    setPiListToSend([])
    setAddNewPiToNetworkModal(true)
  }

  return (
    <>

      {
          deleteModal && (
          <Modal title="Delete Center from Network" visible={deleteModal} onOk={handleDeletePi} onCancel={() => setDeleteModal(false)}>
            Do you really want to delete center from this network ?
          </Modal>
          )
      }
      <div className="whole-deep-dive-page whole-deep-dive-page-inpage">
        <div className="middle-part" style={{ minWidth: '100%' }}>
          <div className="upper-divof-search-Tabs">
            <div className="outer_large_div">
              <div className="network-inner-content-card">
                {networkData.loading || localLoading ? <Loader loading={networkData.loading || localLoading} error={networkData.error} />
                  : (
                    <>
                      <div className="header-network-content">
                        <div className="header-network-content-search">
                          <Search
                            placeholder="Search Center"
                            allowClear
                            value={piValueToSearch}
                            onChange={(e) => setPiValueToSearch(e.target.value)}
                            onSearch={searchPiValue}
                            onPressEnter={searchPiValue}
                            enterButton
                          />
                        </div>
                        <Loader loading={networkData.loading} error={networkData.error}>

                          {!networkData.loading && networkData.flag && (
                          <LazyLoadDiv
                            className="card-list scrollbar"
                            id="pi-list-in-Network"
                            total={networkData.data.data.totalPIs}
                            currentTotal={(networkData.data.data.pi_summary || []).length}
                            loadMoreRows={() => loadMoreRowsPiListInNetwork()}
                            height="calc(100vh - 275px)"
                          >
                            {renderPiList()}
                          </LazyLoadDiv>
                          )}
                        </Loader>
                      </div>
                    </>
                  )}
              </div>
              <div className="footer-button-wrapper footer-button-wrapper-pd save-analysis">
                <div className="plus-add-btn">
                  <button onClick={() => handleAddPiToNetworkClick()} type="button" className="plus-btn">
                    <span className="title" style={{ display: 'none' }}>
                      Add Centers to Network&nbsp;
                    </span>
                    <span className="title-plus"> +</span>
                  </button>
                </div>
              </div>
              {addPiToNetworkModal && (

              <Modal
                title="Add Centers to Network"
                visible={addPiToNetworkModal}
                onOk={handleAddPiToNetwork}
                onCancel={handleAddNewPiToNetworkModalClose}
                width="1000"
                className="add-investigator-network-modal"
                okText="Add"
                maskClosable={false}
                okButtonProps={{ disabled: piListToSend.length === 0 }}
              >

                <div className="add-investigator-to-network">
                  <Input placeholder="Search Centers here" onChange={(e) => onInvestigatorChangeInput(e.target.value)} />
                  <div className="Card">
                    <Loader loading={piAutoSuggest.loading} error={piAutoSuggest.error}>
                      {!piAutoSuggest.loading && piAutoSuggest.flag && (
                      <LazyLoadDiv
                        className="card-list scrollbar"
                        id="pi-list-in-search"
                        total={piAutoSuggest.data.total}
                        currentTotal={(piAutoSuggest.data.data || []).length}
                        loadMoreRows={() => loadMoreRowsPiList()}
                        height="calc(100vh - 275px)"
                      >{renderAutoSuggestOptions()}
                      </LazyLoadDiv>
                      )}
                    </Loader>
                  </div>

                </div>
              </Modal>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NetworkCenterList;
