import React, {
  useEffect, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { stringify } from 'query-string';
import {
  GeoJSON, Map, ScaleControl, CircleMarker, Tooltip, Popup, TileLayer,
} from 'react-leaflet';
import {
  Button, Modal, Spin, Tag,
} from 'antd';
import StarRatings from 'react-star-ratings';
import { getCenterOfGeoJson } from './MapUtils'
import 'leaflet/dist/leaflet.css';
import { getCountryData } from '../GetJsonData';
import ScaleRange from '../ScaleRange'
import './styles.scss'
import { getFilterValuesAction } from '../../AddFilters/logic';
import {
  getShortListedMapBgDataAction,
  getShortListedMapBgDataResetAction,
  getShortListedMapMarkerDataResetAction,
  getShortListedMapMarkerDataAction,
  getProjectPiListAction,
  getProjectPiListResetAction,
  getProjectPiListToRenderAction,
  getProjectPiListToRenderMoreAction,
  getProjectPiListToRenderResetAction,
} from './logic';
import AddFilters from '../../AddFilters';
import { getFormattedDate, getCookie } from '../../../../utils';
import { addPiAction, addPiResetAction } from '../../DocumentList/logic';
import Loader from '../../../../common/components/Loader';
import LazyLoadDiv from '../../../../common/components/LazyLoadDiv';

export default function ChoroplethMapDrillDown({
  project_id, projectFilters,
}) {
  const dispatch = useDispatch()
  const adminType = getCookie('permissions')
  const COUNTRY_VIEW_ID = 'worlddata';
  const defaultColors = ['#489558', '#B1D778', '#DEED97', '#FAE096', '#d9a81c', '#E4754F', '#C74032', '#ec3913', '#b81f0b']
  const views = ['world', 'country', 'state']
  const shortListedMapBgData = useSelector((store) => store.getShortListedMapBgDataResponse);
  const shortListedMapMarkerData = useSelector((store) => store.getShortListedMapMarkerDataResponse);
  const savedProjectData = useSelector((store) => store.savedProjectResultsData);
  const ProjectPiList = useSelector((store) => store.getProjectPiList);
  const ProjectPiListToRender = useSelector((store) => store.projectPiListToRender);
  const addPiResponse = useSelector((store) => store.addPiResponse);

  const markerRef = useRef();
  const shortListedStateData = useRef({})
  const mapRef = useRef(null);
  const popupData = useRef([])
  const bgPiData = useRef([])
  const geoJsonRef = useRef(null);

  const [geoJsonId, setGeoJsonId] = useState(COUNTRY_VIEW_ID);
  const [mapData, setMapData] = useState(getCountryData(COUNTRY_VIEW_ID))
  const [minMaxScale, setMinMaxScale] = useState({ min: undefined, max: undefined })
  const [scaleSplit, setScaleSplit] = useState(7)
  const [scaleMaximize, setScaleMaximize] = useState(true)
  const [rangeObj, setRangeObj] = useState([])
  const [formState, setFormStates] = useState([]);
  const [filterValues, setFilterValues] = useState([]);
  const [isQueryFilterOpen, setIsQueryFilterOpen] = useState(false)
  const [queryFilterData, setQueryFilterData] = useState(undefined)
  const [currentView, setCurrentView] = useState(views[0])
  const [currentCountryName, setCurrentCountryName] = useState(undefined)
  const [currentStateName, setCurrentStateName] = useState(undefined)
  const [piListDataToRender, setPiListDataToRender] = useState([])
  const [from, setFrom] = useState(2)
  const [scaleEditOpen, setScaleEditOpen] = useState(false)
  useEffect(() => {
    if (mapRef.current && geoJsonRef.current) {
      mapRef.current.leafletElement.fitBounds(
        geoJsonRef.current.leafletElement.getBounds(),
      );
    }
  });

  useEffect(() => {
    dispatch(getFilterValuesAction());
    dispatch(getShortListedMapBgDataAction({
      project_id,
      filters: JSON.stringify(queryFilterData),
    }))
    dispatch(getShortListedMapMarkerDataAction({
      project_id,
      filters: JSON.stringify(projectFilters),
    }))
  }, [])

  useEffect(() => {
    setGeoJsonId(COUNTRY_VIEW_ID)
    popupData.current = []
    bgPiData.current = []
    setCurrentView(views[0])
    setRangeObj([])
    setMapData(getCountryData(COUNTRY_VIEW_ID))
    dispatch(getShortListedMapBgDataAction({
      project_id,
      filters: JSON.stringify(queryFilterData),
    }))
    dispatch(getShortListedMapMarkerDataAction({
      project_id,
      filters: JSON.stringify(projectFilters),
    }))
  }, [projectFilters])

  useEffect(() => {
    if (addPiResponse.flag) {
      dispatch(getShortListedMapBgDataAction({
        project_id,
        scale: 'states',
        filters: JSON.stringify({ ...queryFilterData, countries: [currentCountryName], states: [currentStateName] }),
        view: 'list',
        size: 700,
      }))
      dispatch(getProjectPiListAction({
        project_id,
        scale: 'states',
        filters: JSON.stringify({ ...projectFilters, countries: [currentCountryName], states: [currentStateName] }),
        view: 'list',
        size: 700,
      }))
      dispatch(addPiResetAction({}))
    }
  }, [addPiResponse])
  useEffect(() => {
    if (shortListedMapBgData?.flag) {
      if (shortListedMapBgData?.data?.countMapData) {
        processScaleRange(shortListedMapBgData?.data?.minValues?.allPisCount || 0, shortListedMapBgData?.data?.maxValues?.allPisCount || 0)
        shortListedStateData.current = shortListedMapBgData?.data?.countMapData
        setMinMaxScale({ min: shortListedMapBgData?.data?.minValues?.allPisCount || 0, max: shortListedMapBgData?.data?.maxValues?.allPisCount || 0 })
        // setCurrentStateList(shortListedMapBgData?.data?.modifiedOriginalKeyMap)
        dispatch(getShortListedMapBgDataResetAction({}))
      } else if (shortListedMapBgData?.data?.data) {
        bgPiData.current = shortListedMapBgData?.data?.data
        setMinMaxScale({ min: 0, max: 0 })
      }
    }
  }, [JSON.stringify(shortListedMapBgData)])

  useEffect(() => {
    return () => {
      dispatch(getShortListedMapBgDataResetAction({}))
    }
  }, [])
  useEffect(() => {
    if (ProjectPiList?.flag) {
      if (ProjectPiList?.data?.data && currentView === 'state') {
        popupData.current = ProjectPiList?.data?.data
      }
      dispatch(getProjectPiListResetAction({}))
    }
  }, [JSON.stringify(ProjectPiList)])

  useEffect(() => {
    if (ProjectPiListToRender?.flag) {
      if (ProjectPiListToRender?.data) {
        setPiListDataToRender(ProjectPiListToRender?.data?.data)
      }
    }
  }, [JSON.stringify(ProjectPiListToRender)])

  useEffect(() => {
    if (shortListedMapMarkerData?.flag) {
      if (shortListedMapMarkerData?.data?.countMapData) {
        popupData.current = []
        const countrydata = getCountryData(currentView === 'country' ? processName(currentCountryName) : geoJsonId).features
        countrydata.map((e) => {
          if (Object.keys(shortListedMapMarkerData?.data?.countMapData).includes(processName(e?.properties?.NAME_1))) {
            if (shortListedMapMarkerData?.data?.countMapData[processName(e?.properties?.NAME_1)]?.projectPisCount) {
              popupData.current.push({ ...shortListedMapMarkerData?.data?.countMapData[processName(e?.properties?.NAME_1)], center: getCenterOfGeoJson(e) })
            }
          }
          return null
        })
        dispatch(getShortListedMapMarkerDataResetAction({}))
      }
    }
  }, [JSON.stringify(shortListedMapMarkerData)])

  const mapCenter = getCenterOfGeoJson(mapData);

  const processName = (e) => {
    return e.toLowerCase().replaceAll(' ', '').replaceAll('-', '')
  }

  const onDrillDown = (e) => {
    setCurrentView(views[views.indexOf(currentView) + 1 ? views.indexOf(currentView) + 1 : 2])
    if (getCountryData(processName(e.target.feature?.properties?.NAME_1))) {
      setRangeObj([])
      setMinMaxScale({ min: undefined, max: undefined })
      setCurrentCountryName(e.target.feature?.properties?.NAME_1)
      setMapData(getCountryData(processName(e.target.feature?.properties?.NAME_1)))
      setGeoJsonId(processName(e.target.feature?.properties?.NAME_1));
      dispatch(getShortListedMapBgDataAction({
        project_id,
        scale: 'states',
        filters: JSON.stringify({ ...queryFilterData, countries: [e.target.feature?.properties?.NAME_1] }),
      }))
      dispatch(getShortListedMapMarkerDataAction({
        project_id,
        scale: 'states',
        country_name: e.target.feature?.properties?.NAME_1,
        filters: JSON.stringify(projectFilters),
      }))
    } else if (views[views.indexOf(currentView) + 1 ? views.indexOf(currentView) + 1 : 2] === 'state' && currentCountryName) {
      setMapData(e.target.feature)
      setGeoJsonId(processName(e.target.feature?.properties?.NAME_1));
      // const currentState = currentStateList[processName(e.target.feature?.properties?.NAME_1)]
      setCurrentStateName(e.target.feature?.properties?.NAME_1)
      dispatch(getShortListedMapBgDataAction({
        project_id,
        scale: 'states',
        filters: JSON.stringify({ ...queryFilterData, countries: [currentCountryName], states: [e.target.feature?.properties?.NAME_1] }),
        view: 'list',
        size: 700,
      }))
      dispatch(getProjectPiListAction({
        project_id,
        scale: 'states',
        filters: JSON.stringify({ ...projectFilters, countries: [currentCountryName], states: [e.target.feature?.properties?.NAME_1] }),
        view: 'list',
        size: 700,
      }))
      // popupData.current = []
    } else {
      setMapData(e.target.feature)
      setGeoJsonId(processName(e.target.feature?.properties?.NAME_1));
    }
  };

  console.log('currentView-->', currentView)
  const setFormState = (e) => {
    if (e) {
      setFormStates(e)
    } else { setFormStates([]) }
  }

  const randomHexColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i += 1) {
      color += letters[(Math.floor(Math.random() * 16))];
    }
    return color
  }

  const processScaleRange = (minVal, Maxval) => {
    if (rangeObj.length === 0) {
      let lowerBound = 0
      let upperBound = 0
      let id = 0
      const temp = rangeObj
      const diff = Math.round((Maxval - minVal) / scaleSplit)
      while (upperBound <= Maxval) {
        upperBound = lowerBound + diff
        const tempRangeObj = {
          id,
          lowerBound,
          upperBound,
          color: defaultColors[id] ? defaultColors[id] : randomHexColor(),
        }
        temp.push(tempRangeObj)
        lowerBound = upperBound + 1
        id += 1
      }
      setRangeObj(temp)
    }
  }

  function getColor(d) {
    // const obj = rangeObj.find((itm) => ((itm.lowerBound <= d) && (d <= itm.lowerBound)))
    for (let i = 0; i < rangeObj.length; i += 1) {
      if (rangeObj[i].lowerBound <= d) {
        if (rangeObj[i].upperBound >= d) {
          return rangeObj[i].color
        }
      }
    }
    return '#00000'
  }

  const handlebackClicked = () => {
    if (currentView === 'state' && getCountryData(processName(currentCountryName))) {
      setRangeObj([])
      setCurrentView(views[views.indexOf(currentView) - 1 ? views.indexOf(currentView) - 1 : 0])
      setMinMaxScale({ min: undefined, max: undefined })
      setMapData(getCountryData(processName(currentCountryName)))
      setGeoJsonId(processName(currentCountryName));
      dispatch(getShortListedMapBgDataAction({
        project_id,
        scale: 'states',
        filters: JSON.stringify({ ...queryFilterData, countries: [currentCountryName] }),
      }))
      dispatch(getShortListedMapMarkerDataAction({
        project_id,
        scale: 'states',
        country_name: currentCountryName,
        filters: JSON.stringify(projectFilters),
      }))
    } else {
      setCurrentView(views[views.indexOf(currentView) - 1 ? views.indexOf(currentView) - 1 : 0])
      setCurrentCountryName(undefined)
      setRangeObj([])
      setMinMaxScale({ min: undefined, max: undefined })
      dispatch(getShortListedMapBgDataAction({
        project_id,
        filters: JSON.stringify(queryFilterData),
      }))
      dispatch(getShortListedMapMarkerDataAction({
        project_id,
        filters: JSON.stringify(projectFilters),
      }))
      setMapData(getCountryData('worlddata'))
      setGeoJsonId('worlddata');
    }
    bgPiData.current = []
    popupData.current = []
  }

  const getValue = (val) => {
    const temp = formState.filter((itm) => itm.type === val);
    if (val === 'Protocol Title') {
      return temp[0]?.data || undefined;
    }
    if (val === 'Alliance' || val === 'Parexel Study Exists') {
      if ((temp[0] && temp[0].data && temp[0].data === 'yes') || (temp[0] && temp[0].data && temp[0].data === 'Yes')) {
        return 'Yes';
      }
      if ((temp[0] && temp[0].data && temp[0].data === 'no') || (temp[0] && temp[0].data && temp[0].data === 'No')) {
        return 'No';
      }
      return undefined;
    }
    if (val === 'Study IDs') {
      if (Array.isArray(temp[0]?.data)) {
        return temp[0]?.data
      }
      const a = temp[0]?.data
      if (a) {
        return a.split(',')
      }
    }
    if (Array.isArray(temp[0]?.data) && temp[0]?.data.length) {
      return temp[0]?.data
    } if (typeof temp[0]?.data === 'string' && temp[0]?.data !== 0) {
      return temp[0]?.data
    } return undefined
  };

  const getStars = (value) => {
    if (value === 'Class 3') {
      return 1;
    }
    if (value === 'Class 2') {
      return 2;
    }
    if (value === 'Class 1') {
      return 3;
    }
    return 0;
  };

  const handleQueryFilterApply = () => {
    setGeoJsonId(COUNTRY_VIEW_ID)
    popupData.current = []
    bgPiData.current = []
    setCurrentView(views[0])
    setRangeObj([])
    setMapData(getCountryData(COUNTRY_VIEW_ID))
    const filters = {
      indications: getValue('Therapeutic Interest') || undefined,
      indications_from_trials: getValue('Indications From Trials') || undefined,
      sponsors:
        getValue('Sponsor')?.map((itm) => {
          return { name: itm.value, affiliation_id: itm.key };
        }) || [],
      therapeutic_area: getValue('Therapy Area') || undefined,
      phases: getValue('Study Phase') || undefined,
      countries: getValue('Country') || undefined,
      alliance_type: getValue('Alliance Type') || undefined,
      all_trial_ids: getValue('Protocol Number / Trial ID') || undefined,
      pi_sources: getValue('PI Source') || undefined,
      sub_regions: getValue('Sub-Region') || [],
      primary_center_types: getValue('Primary Center Type') || undefined,
      regions: getValue('Region') || undefined,
      specialities: getValue('Speciality') || undefined,
      parexel_study_ids: getValue('Parexel Study ID') || undefined,
      age_range_groups: getValue('Age Range Group') || undefined,
      alliance: getValue('Alliance') || undefined,
      parexel_study: getValue('Parexel Study Exists') || undefined,
      pi_names: getValue('PI Name') || undefined,
      protocol_title: getValue('Protocol Title') || undefined,
      start_date: getValue('Start Date') || undefined,
      end_date: getValue('End Date') || undefined,
      tier: getValue('Profile Tier')?.map(Number) || undefined,
      compliance: getValue('Compliance') || undefined,
      study_ids: getValue('Study IDs') || undefined,
      networks_names: getValue('Investigator Network Names') || undefined,
      kol_score: Number(getValue('Kol Score')) || undefined,
      start_up: getValue('Start Up Months') || undefined,
      industry_studies: Number(getValue('Industry Studies')) || undefined,
      race_ethnicity: getValue('Race & Ethnicity') || undefined,
      income_by_insurance: getValue('Income by Insurance') || undefined,
      employment_by_insurance: getValue('Employment by Insurance') || undefined,
      center_name: getValue('Center Name') || undefined,
      alliance_name: getValue('Alliance Name') || undefined,
      parent_names: getValue('Parent Institution Names') || undefined,
      center_networks: getValue('Center Network Names') || undefined,
    }
    setQueryFilterData(filters)
    dispatch(getShortListedMapBgDataAction({
      filters: JSON.stringify(filters),
      scale: currentView === 'state' ? 'states' : undefined,
      country_name: currentView === 'state' ? currentCountryName : undefined,
    }))
    dispatch(getShortListedMapMarkerDataAction({
      filters: JSON.stringify(projectFilters),
      project_id,
    }))
  }

  function geoJSONStyle(e) {
    if (Object.keys(shortListedStateData.current).includes(processName(e?.properties?.NAME_1))) {
      return {
        color: '#1f2021',
        weight: 1,
        fillOpacity: currentView !== 'state' ? 0.7 : 0,
        fillColor: getColor(shortListedStateData.current[processName(e?.properties?.NAME_1)]?.allPisCount),
      };
    } return {
      color: '#1f2021',
      weight: 1,
      // fillOpacity: 0,
      fillOpacity: currentView !== 'state' ? 0.7 : 0,
      fillColor: '#138075',
    };
  }

  const handleAddPiToProject = (itm) => {
    popupData.current = []
    dispatch(addPiAction({
      project_id,
      site_id: itm.affiliated_site_id,
      pi_id: itm.id,
      is_cfl: adminType.includes('cfl_access') ? true : undefined,
      is_lcor: adminType.includes('lcor_access') ? true : undefined,
    }))
  }

  const getPiCard = (item, type) => (
    <div className="popUp-second">
      <div className="pimap-main-anlysis-name-wrapper">
        {item.name}
        {type !== 'non_project' && (<div className="checked-in-project" title="This Investigator is present in project" />) }
      </div>
      <div className="popUp-fifth map-popup-pis-list-wrap">
        <div className="map-popup-pis-list map-result-list-card">
          <div className="map-popup-pi-card-wrap map-popup-pi-card-wrap-anl">
            <a
              className="map-result-list-card-title map-result-list-card-title-link"
              title={item?.pi_name}
              target="_blank"
              href={`/search/investigatorprofile?${stringify({
                query: item?.pi_name,
                id: item?.pi_id,
                type: 'PI',
              })}`}
              rel="noreferrer noopener"
            >
              {item?.pi_name}
            </a>
            <div className="map-result-list-card-content">
              <div className="content-title">Primary Organization: </div>
              <div className="map-location">{item?.primary_organization ? item?.primary_organization.name : '-'}</div>
              <div className="content-title">Address: </div>
              <div className="map-location">{item?.primary_organization ? `${item?.primary_organization.city}, ${item?.primary_organization.state}, ${item?.primary_organization.country}, ${item?.primary_organization.zip}` : '-'}</div>
              <div className="content-title">LandSCAPE id: </div>
              <div className="map-location">{item?.landscape_id ? item?.landscape_id : '-'}</div>
            </div>
            <div className="map-result-list-card-rating">
              <div className="rating">
                <div className="reliability rating-sec">
                  <div className="rating-sec-title">Reliability: </div>
                  {item.reliability_class ? (
                    <StarRatings
                      rating={getStars(item.reliability_class)}
                      starRatedColor="#8A0050"
                      numberOfStars={3}
                      starDimension="16px"
                      starSpacing="1px"
                      starEmptyColor="#D099B9"
                    />
                  ) : (
                    <StarRatings
                      rating={0}
                      starRatedColor="#8A0050"
                      numberOfStars={3}
                      starDimension="16px"
                      starSpacing="1px"
                      starEmptyColor="#D099B9"
                    />
                  )}
                </div>
                <div className="reliability rating-sec">
                  <div className="rating-sec-title">Quality:</div>
                  {item?.quality_rating !== ''
                    ? (
                      <StarRatings
                        rating={item?.quality_rating}
                        starRatedColor="#8A0050"
                        numberOfStars={3}
                        starDimension="16px"
                        starSpacing="1px"
                        starEmptyColor="#D099B9"
                      />
                    )
                    : (
                      <div className="dash-nodata">-</div>
                    )}
                </div>
              </div>
            </div>
            <div className="map-result-list-card-content">
              <div className="content-title">Specialities:</div>
              {item?.specialties && item?.specialties.length
                ? (
                  <div>{item?.specialties.join('; ')}</div>
                )
                : (
                  <div className="dash-nodata">-</div>
                )}
            </div>
            <div className="map-result-list-card-content">
              <div className="content-title">Last Trial Start Date:</div>
              {item?.recent_trial_start_date_ts
                ? (
                  <div>{getFormattedDate(item?.recent_trial_start_date_ts * 1000)}</div>
                )
                : (
                  <div className="dash-nodata">-</div>
                )}
            </div>
            <div className="map-result-list-card-content">
              <div className="map-tag">
                <Tag>
                  <div className="details-abt-tags">
                    <div className="nameOfTag"># Industry Studies</div>
                    <div className="sizeOftag">{item?.industry_studies}</div>
                  </div>
                </Tag>
                {item?.tier !== ''
                  ? (
                    <Tag>
                      <div className="details-abt-tags">
                        <div className="nameOfTag">Tier</div>
                        <div className="sizeOftag">{item?.tier}</div>
                      </div>
                    </Tag>
                  )
                  : null}
                <Tag>
                  <div className="details-abt-tags">
                    <div className="nameOfTag" title="KOL scores are derived based on research/information authored by the investigator like publications, clinical trials, congress articles, patents, grants, guidelines etc. Each document is scored considering several factors like type of document, recency of the document, role of the investigator in a given document, source where the document is published etc. Scores for each individual document is aggregated and weighted by data class, to calculate the raw KOL score. The raw KOL score for the investigators is then numerically scaled between 0 to 100 considering rest of the investigators in LandSCAPE.">KOL Score</div>
                    <div className="sizeOftag">{item?.cls_kol_score}</div>
                  </div>
                </Tag>
              </div>
            </div>
          </div>
          {type === 'non_project'
        && (<div className="add-pi-to-project-btn"> <Button onClick={() => handleAddPiToProject(item)}>Add to Project</Button></div>
        )}
        </div>
      </div>
    </div>
  )

  const popUpData = (item, type) => {
    return (
      <Popup className="pimap-main pimap-main-anlysis map-center" minWidth={200} closeButton={false}>
        <div
          className="popUp-first"
          tabIndex={0}
          role="button"
          onKeyDown={null}
        >
          {getPiCard(item, type)}
        </div>
      </Popup>
    );
  };

  const ListApiCall = (item) => {
    if (currentView === 'country' && (currentCountryName === null || currentCountryName === undefined)) {
      dispatch(getProjectPiListToRenderAction({
        project_id,
        scale: currentView === 'country' ? 'states' : 'countries',
        filters: JSON.stringify({ ...projectFilters, countries: [item?.name] }),
        view: 'list',
        size: 5000,
      }))
    } else {
      dispatch(getProjectPiListToRenderAction({
        project_id,
        scale: currentView === 'country' ? 'states' : 'countries',
        filters: JSON.stringify({ ...projectFilters, countries: currentView === 'country' ? [currentCountryName] : [item?.name], states: currentView === 'country' ? [item?.name] : undefined }),
        view: 'list',
        size: 5000,
      }))
    }
  }

  const loadMoreRows = () => {
    dispatch(getProjectPiListToRenderMoreAction({
      project_id,
      scale: 'countries',
      filters: JSON.stringify({ ...projectFilters, countries: ['India'] }),
      view: 'list',
      size: 5000,
      from,
    }))
    setFrom(from + 1)
  }

  const handleListTooltipClose = () => {
    getProjectPiListToRenderResetAction({})
    setFrom(2)
  }

  const renderListOfPis = (item) => {
    return (
      <Popup
        className="pimap-main pimap-main-anlysis map-center"
        minWidth={400}
        closeButton={false}
        onOpen={() => {
          ListApiCall(item)
        }}
        onClose={() => {
          handleListTooltipClose()
        }}
      >
        <Loader loading={ProjectPiListToRender.loading} style={{ height: '100px' }}>
          <div className="title-pi-card-List">{ currentView === 'country' && currentCountryName !== null && currentCountryName !== undefined ? `${item?.name}, ${currentCountryName}` : item?.name }</div>
          <LazyLoadDiv
            className="card-list scrollbar"
            id="analysis-list"
            total={ProjectPiListToRender.data.total}
            currentTotal={(piListDataToRender || []).length}
            loadMoreRows={() => loadMoreRows()}
          >
            <div
              className="popUp-first"
              tabIndex={0}
              role="button"
              onKeyDown={null}
            >
              {piListDataToRender.length && piListDataToRender.map((itm) => (getPiCard(itm, 'in_project')))}
            </div>
          </LazyLoadDiv>
          <div className="show-doc-outof show-doc-outof-w-relative" style={{ marginBottom: '10px' }}>
            <div className="show-doc-data">
              Showing<span className="show-count">{piListDataToRender?.length}</span> out of
              <span className="show-count-number">{ProjectPiListToRender.data.total}</span>
            </div>
          </div>
        </Loader>
      </Popup>
    )
  }

  const showScale = () => {
    return (
      <Spin spinning={shortListedMapBgData.loading || savedProjectData.loading || shortListedMapMarkerData.loading} error={shortListedMapBgData.error}>
        <div className="mapMainContainer-scale-content-in">
          { rangeObj.map((itm) => (
            <div className="mapMainContainer-scale-content-in-sec" id={itm.id}>
              <div className="mapMainContainer-scale-color" style={{ backgroundColor: itm.color }} />
              <div className="mapMainContainer-scale-range">
                {itm.lowerBound} - {itm.upperBound > minMaxScale.max ? `${minMaxScale.max}+` : itm.upperBound}
              </div>
            </div>
          ))}
        </div>
      </Spin>
    )
  }

  return (
    <Spin spinning={shortListedMapBgData.loading || savedProjectData.loading || shortListedMapMarkerData.loading || addPiResponse.loading} error={shortListedMapBgData.error}>
      <div className="mapMainContainer">
        <div className="mapMainContainer-filter-container">
          {!isQueryFilterOpen && (
            <div className="filter-button-open" role="presentation" onClick={() => { setIsQueryFilterOpen(true) }}>
              <div className="filter-button-img" />
              <div className="filter-open-arrow-img" />
            </div>
          )}
          {isQueryFilterOpen
            && (
              <div className="mapMainContainer-filter-section">
                <div className="mapMainContainer-filter-content">
                  <div className="title">Filters</div>
                  <div className="query-wrap-section">
                    <div className="query-wrap open">
                      <div className="project-select-tags">
                        <><AddFilters
                          forms={{ formState, setFormState }}
                          filters={{ filterValues, setFilterValues }}
                        />
                          <div className="footer-button-wrapper footer-button-wrapper-pd">
                            <Button
                              className="cancel-wrapper cancel-btn ant-btn-default"
                              onClick={() => {
                                setIsQueryFilterOpen(false)
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="primary"
                              className="save-wrapper primary-btn ant-btn-primary"
                              onClick={() => {
                                handleQueryFilterApply()
                                setIsQueryFilterOpen(false)
                              }}
                            >
                              Filter
                            </Button>
                          </div>
                        </>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="filter-close-arrow-img" role="presentation" onClick={() => setIsQueryFilterOpen(false)} />
              </div>
            )}
        </div>
        <Map className="map" center={mapCenter} zoom={2} minZoom={2} ref={mapRef} zoomControl={false}>
          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            // url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
            minZoom={2}
            maxZoom={19}
          />
          <GeoJSON
            data={mapData}
            key={geoJsonId}
            style={(e) => geoJSONStyle(e)}
            onEachFeature={currentView !== 'state' ? onEachFeature : null}
            ref={geoJsonRef}
            id={geoJsonId}
          />
          {/* <div className="map-full-screen-button">
            <Button className="map-full-screen-button-icon" onClick={() => setMapFullScreen(!mapFullScreen)} />
          </div> */}
          {geoJsonId !== 'worlddata'
            && (
              <div className="map-back-screen-button">
                <Button className="back-btn" id="map-back-btn" type="primary" onClick={() => handlebackClicked()}>
                  back
                </Button>
              </div>
            )}
          {popupData.current.length
            ? popupData?.current.map((item, ind) => (
              <CircleMarker
                center={item?.center ? item?.center : [item?.primary_organization?.lat, item?.primary_organization?.lon]}
                markerRef={markerRef}
                fillOpacity={0.5}
                className="circle-marker-map"
                radius={10}
                key={ind}
              >
                <Tooltip
                  direction="top"
                  permanent
                  interactive
                  className="run-analysis-map-tooltip"
                >
                  {item?.projectPisCount ? item?.projectPisCount
                    : (
                      <div className="checked-in-project" />
                      )}
                </Tooltip>
                {item?.projectPisCount ? renderListOfPis(item) : popUpData(item)}
              </CircleMarker>
            )) : null}
          {bgPiData.current.length
            ? bgPiData?.current.map((item, ind) => (
              <CircleMarker
                center={item?.center ? item?.center : [item?.primary_organization?.lat, item?.primary_organization?.lon]}
                markerRef={markerRef}
                fillOpacity={1}
                className="circle-marker-map-background-data"
                radius={10}
                key={ind}
              >
                {popUpData(item, 'non_project')}
              </CircleMarker>
            )) : null}
          <ScaleControl />
        </Map>
        <div className="mapMainContainer-scale">
          <div className="mapMainContainer-scale-main">
            { currentView !== 'state'
              ? (
                <>
                  <div className={`head-col${scaleMaximize ? 'open head-col' : 'close head-col'}`}>
                    <div className="scale-title"># Investigators</div>
                    <div className="scale-dropdown">
                      <Button
                        className={`scale-maximize-icon${scaleMaximize ? 'open' : 'close'}`}
                        onClick={() => {
                          setScaleMaximize(!scaleMaximize)
                        }}
                        type="primary"
                      />
                    </div>
                  </div>
                  {scaleMaximize && (
                  <div className="mapMainContainer-scale-content">
                    {showScale()}
                    <div className="mapMainContainer-scale-edit-button" role="presentation" onClick={() => setScaleEditOpen(true)} />
                  </div>
                  )}
                </>
              ) : (
                <>
                  <div className={`head-col${scaleMaximize ? 'open head-col' : 'close head-col'}`}>
                    <div className="scale-title">Legends</div>
                    <div className="scale-dropdown">
                      <Button
                        className={`scale-maximize-icon${scaleMaximize ? 'open' : 'close'}`}
                        onClick={() => {
                          setScaleMaximize(!scaleMaximize)
                        }}
                        type="primary"
                      />
                    </div>
                  </div>
                  {scaleMaximize && (
                  <div className="mapMainContainer-scale-content">
                    <div className="marker-section-parent">
                      <div className="marker-section">
                        <div className="marker-inproject-icon marker-icon" />
                        <div className="marker-inproject-label marker-label">
                          Investigators present <br />
                          in project
                        </div>
                      </div>
                      <div className="marker-section">
                        <div className="marker-in-project-icon marker-icon" />
                        <div className="marker-not-in-project-label marker-label">
                          Investigators not present <br />
                          in project
                        </div>
                      </div>
                    </div>
                  </div>
                  )}
                </>
              )}
          </div>
        </div>
        { scaleEditOpen && (
          <Modal
            className="scale-modal"
            open={scaleEditOpen}
            title="Scale Range"
            onCancel={() => setScaleEditOpen(false)}
            footer={null}
          >
            <ScaleRange minMaxScale={minMaxScale} setMinMaxScale={setMinMaxScale} rangeObj={rangeObj} setRangeobj={setRangeObj} closePopover={setScaleEditOpen} scaleSplit={scaleSplit} setScaleSplit={setScaleSplit} />
          </Modal>
        )}
      </div>
    </Spin>
  );
  function onEachFeature(e, layer) {
    // const layerUtils = layersUtils(geoJsonRef, mapRef);
    layer.on({
      // mouseover: layerUtils.highlightOnClick,
      // mouseout: layerUtils.resetHighlight,
      click: onDrillDown,
    });
  }
}
