import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { parse } from 'query-string';
import { Table } from 'antd';
import { getMedidataSiteOrPIAction, getMedidataSiteOrPIMoreAction } from './logic';
import LazyLoadDiv from '../../../common/components/LazyLoadDiv';
import Loader from '../../../common/components/Loader';

const MedidataMetrics = () => {
  const dispatch = useDispatch();
  const [from, setFrom] = useState(1);
  const MedidataSiteResults = useSelector((store) => store.getMedidataSiteOrPIResults);
  const [data, setData] = useState({
    site_enrollment_metrics: [],
    site_landscape_metrics: [],
    site_quality_metrics: [],
  });
  const [activeTab, setActiveTab] = useState('site_enrollment_metrics');

  const site_enrollment_metrics_cols = [
    {
      title: 'Therapy Area',
      dataIndex: 'Therapeutic_area',
      key: 'Therapeutic_area',
    },
    {
      title: 'Indication',
      dataIndex: 'Indication',
      key: 'Indication',
    },
    {
      title: 'Enrollment Rate',
      dataIndex: 'industry_enrollment_rate',
      key: 'industry_enrollment_rate',
    },
    {
      title: 'Enrollment Percentile',
      dataIndex: 'industry_enrollment_percentile',
      key: 'industry_enrollment_percentile',
    },
    {
      title: 'Screen Failure Rate',
      dataIndex: 'industry_screen_failure_rate',
      key: 'industry_screen_failure_rate',
    },
    {
      title: 'Enrolled Subjects',
      dataIndex: 'industry_enrolled_subjects',
      key: 'industry_enrolled_subjects',
    },
  ];

  const site_landscape_metrics_cols = [
    {
      title: 'Therapy Area',
      dataIndex: 'Therapeutic_area',
      key: 'Therapeutic_area',
    },
    {
      title: 'Indication',
      dataIndex: 'Indication',
      key: 'Indication',
    },
    {
      title: 'Open Enrollment Studies',
      dataIndex: 'open_enrollment_studies',
      key: 'open_enrollment_studies',
    },
    {
      title: 'Closed Enrollment Studies',
      dataIndex: 'closed_enrollment_studies',
      key: 'closed_enrollment_studies',
    },
    {
      title: 'Relative Trial Congestion',
      dataIndex: 'relative_trial_congestion',
      key: 'relative_trial_congestion',
    },
  ];

  const site_quality_metrics_cols = [
    {
      title: 'Therapy Area',
      dataIndex: 'Therapeutic_area',
      key: 'Therapeutic_area',
    },
    {
      title: 'Indication',
      dataIndex: 'Indication',
      key: 'Indication',
    },
    {
      title: 'Data Lag',
      dataIndex: 'industry_data_lag',
      key: 'industry_data_lag',
    },
    {
      title: 'Data Correction Rate',
      dataIndex: 'industry_data_correction_rate',
      key: 'industry_data_correction_rate',
    },
    {
      title: 'Overall Data Lag',
      dataIndex: 'overall_data_lag',
      key: 'overall_data_lag',
    },
    {
      title: 'Overall Data Correction Rate',
      dataIndex: 'overall_data_correction_rate',
      key: 'overall_data_correction_rate',
    },
  ];
  useEffect(() => {
    dispatch(
      getMedidataSiteOrPIAction({
        id: parse(location.search).id,
        dataset: 'centers',
        from: 1,
        size: 10,
      }),
    );
  }, []);

  useEffect(() => {
    if (MedidataSiteResults.flag) {
      setData({
        site_enrollment_metrics: MedidataSiteResults?.data?.data?.map((itm) => ({ Indication: itm?.Indications, Therapeutic_area: itm?.Therapeutic_area, ...itm?.site_enrollment_metrics })),
        site_landscape_metrics: MedidataSiteResults?.data?.data?.map((itm) => ({ Indication: itm?.Indications, Therapeutic_area: itm?.Therapeutic_area, ...itm?.site_landscape_metrics })),
        site_quality_metrics: MedidataSiteResults?.data?.data?.map((itm) => ({ Indication: itm?.Indications, Therapeutic_area: itm?.Therapeutic_area, ...itm?.site_quality_metrics })),
      })
    }
  }, [MedidataSiteResults]);

  const loadMoreRow = () => {
    setFrom(from + 1)
    dispatch(getMedidataSiteOrPIMoreAction({
      id: parse(location.search).id,
      dataset: 'centers',
      from: from + 1,
      size: 10,
    }))
  }

  const renderActiveTabContent = () => {
    switch (activeTab) {
      case 'site_enrollment_metrics':
        return (
          <div className="CardList">
            <LazyLoadDiv
              className="card-list scrollbar"
              id="pi-list-in-search"
              total={MedidataSiteResults?.data?.total}
              currentTotal={(MedidataSiteResults?.data?.data || []).length}
              loadMoreRows={() => loadMoreRow()}
              height="calc(100vh - 200px)"
            >
              <Table
                columns={site_enrollment_metrics_cols}
                dataSource={data?.site_enrollment_metrics}
                pagination={false}
              />
            </LazyLoadDiv>
          </div>
        );
      case 'site_landscape_metrics':
        return (
          <div className="CardList">
            <LazyLoadDiv
              className="card-list scrollbar"
              id="pi-list-in-search"
              total={MedidataSiteResults?.data?.total}
              currentTotal={(MedidataSiteResults?.data?.data || []).length}
              loadMoreRows={() => loadMoreRow()}
              height="calc(100vh - 200px)"
            >
              <Table
                columns={site_landscape_metrics_cols}
                dataSource={data?.site_landscape_metrics}
                pagination={false}
              />
            </LazyLoadDiv>
          </div>
        );
      case 'site_quality_metrics':
        return (
          <div className="CardList">
            <LazyLoadDiv
              className="card-list scrollbar"
              id="pi-list-in-search"
              total={MedidataSiteResults?.data?.total}
              currentTotal={(MedidataSiteResults?.data?.data || []).length}
              loadMoreRows={() => loadMoreRow()}
              height="calc(100vh - 200px)"
            >
              <Table columns={site_quality_metrics_cols} dataSource={data?.site_quality_metrics} pagination={false} />
            </LazyLoadDiv>
          </div>
        );
      default:
        return <div>No Data</div>;
    }
  };

  const onTabChange = (tabName) => {
    setActiveTab(tabName);
    setFrom(1)
    dispatch(
      getMedidataSiteOrPIAction({
        id: parse(location.search).id,
        dataset: 'centers',
        from: 1,
        size: 10,
      }),
    );
  }

  return (
    <Loader loading={MedidataSiteResults.loading}>
      <div className="qualitymatrices-mainsection-center">
        <div className="qualitymatrices-tabsection">
          <div className="qualitymatrices-tabsection-card card">
            <div
              role="button"
              tabIndex={0}
              className={`covidstats-tab ${activeTab === 'site_enrollment_metrics' ? 'active' : ''}`}
              onClick={() => onTabChange('site_enrollment_metrics')}
            >
              Site Enrollment Metrics
            </div>
            <div
              role="button"
              tabIndex={0}
              className={`covidstats-tab ${activeTab === 'site_landscape_metrics' ? 'active' : ''}`}
              onClick={() => onTabChange('site_landscape_metrics')}
            >
              Site Landscape Metrics
            </div>
            <div
              role="button"
              tabIndex={0}
              className={`covidstats-tab ${activeTab === 'site_quality_metrics' ? 'active' : ''}`}
              onClick={() => onTabChange('site_quality_metrics')}
            >
              Site Quality Metrics
            </div>
          </div>
        </div>
        <div className="qualitymatrices-tabledata">
          <div className="qualitymatrices-tabledata-card card">{renderActiveTabContent()}</div>
        </div>
      </div>
    </Loader>
  );
};

export default MedidataMetrics;
